import React from 'react';

const Error = () => {
    return (
        <div>
            <h1 style={{color: 'red', marginTop: '70px'}}>
                Такой страницы не существует
            </h1>
        </div>
    );
};

export default Error;