import React from 'react';
import AnimationText from '../components/animationText/AnimationText';

const About = () => {
    return (
        <div style={{marginTop: '70px'}}>
            <AnimationText />
        </div>
    );
};

export default About;